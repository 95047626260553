import React from "react"
import BlogApi from "../../graphql/blog"
import { Tabs, Tab } from "react-bootstrap"
import { Heading, BlogFeature, Container, Layout, SEO } from "src/sws-ui"

const BlogLandingPage = () => {
  const BlogApidata1 = BlogApi()
  var BlogApidata = BlogApidata1.allMarkdownRemark.nodes.filter((data) => data.frontmatter.category_language === 'English');

  const most_recent_blog =
    BlogApidata.length > 0 &&
    BlogApidata[0]

  
  return (
    <Layout>
      <SEO title="Blogs" />
      <Container gridTemplateRows="8">
        <Heading
          size="2"
          col="col-1-9"
          row="row-1"
          className="blog-title"
          style={{ marginTop: "50px" }}
        >
          Blogs
        </Heading>
        <BlogFeature
          heading={most_recent_blog.frontmatter.title}
          content={most_recent_blog.frontmatter.summary}
          col="col-1-7 col-md-center"
          row="row-2 row-md-2"
          category={most_recent_blog.frontmatter.category}
          image={
            most_recent_blog.frontmatter.image.split("/")[
              most_recent_blog.frontmatter.image.split("/").length - 1
            ]
          }
          detailpagelink={
            "/blogs/" + most_recent_blog.frontmatter.title.replace(/\s/g, "_")
          }
        ></BlogFeature>
        <div className="row-5 col-full" style={{marginTop: '50px'}}>
          <Tabs defaultActiveKey="Culture" id="uncontrolled-tab-example">
            <Tab eventKey="Culture" title="Culture">
                  <div className="container">
              {BlogApidata.map((data, counter) => {
                return (
                  <>
                    {data.frontmatter.category.toLowerCase() == "culture" && (
                      <>
                      <div style={{gridColumnEnd: 'span 1'}}></div>
                      <BlogFeature
                        heading={data.frontmatter.title}
                        content={data.frontmatter.summary}
                        category={data.frontmatter.category}
                        image={
                          data.frontmatter.image.split("/")[
                            data.frontmatter.image.split("/").length - 1
                          ]
                        }
                        detailpagelink={
                          "/blogs/" + data.frontmatter.title.replace(/\s/g, "_")
                        }
                      ></BlogFeature>
                      <div style={{gridColumnEnd: 'span 1'}}></div>
                      </>
                    )}
                  </>
                )
              })}
                  </div>
            </Tab>
            <Tab eventKey="Event" title="Event">
              <>
                    <div className="container">
                {BlogApidata.map((data, counter) => {
                  return (
                    <>
                      {data.frontmatter.category.toLowerCase() == "event" && (
                        <>
                        <div style={{gridColumnEnd: 'span 1'}}></div>
                        <BlogFeature
                          heading={data.frontmatter.title}
                          content={data.frontmatter.summary}
                          category={data.frontmatter.category}
                          image={
                            data.frontmatter.image.split("/")[
                              data.frontmatter.image.split("/").length - 1
                            ]
                          }
                          detailpagelink={
                            "/blogs/" +
                            data.frontmatter.title.replace(/\s/g, "_")
                          }
                        ></BlogFeature>
                        <div style={{gridColumnEnd: 'span 1'}}></div>
                        </>
                      )}
                    </>
                  )
                })}
                </div>
              </>
            </Tab>
            <Tab eventKey="Company" title="Company">
              <>
                    <div className="container">
                {BlogApidata.map((data, counter) => {
                  return (
                    <>
                      {
                      data.frontmatter.category.toLowerCase() == "company" && (
                        <>
                        <div style={{gridColumnEnd: 'span 1'}}></div>
                        <BlogFeature
                          heading={data.frontmatter.title}
                          content={data.frontmatter.summary}
                          category={data.frontmatter.category}
                          image={
                            data.frontmatter.image.split("/")[
                              data.frontmatter.image.split("/").length - 1
                            ]
                          }
                          detailpagelink={
                            "/blogs/" +
                            data.frontmatter.title.replace(/\s/g, "_")
                          }
                        ></BlogFeature>
                        <div style={{gridColumnEnd: 'span 1'}}></div>
                        </>
                      )}
                    </>
                  )
                })}
                </div>
              </>
            </Tab>
          </Tabs>
        </div>
      </Container>
    </Layout>
  )
}

export default BlogLandingPage
